import React from 'react';
import logo from "../images/logo-black.svg";
import wLogo from "../images/logo-white.svg";
import appStore from "../images/app-store.svg";
import googlePlay from "../images/google-play.svg";
import yn from "../images/yn.svg";
import gm from "../images/gm.svg";
import twoGis from "../images/2gis.svg";
import qr from "../images/qr.png";

const Main = () => {
    const getPlatform = () => {
        if(navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)){
            return <img src={appStore} alt="app store"/>
        }else if(navigator.userAgent.match(/Android/i)){
            return <img src={googlePlay} alt="google play"/>
        } else {
            return <>
                <img src={googlePlay} alt="google play"/>
                <img src={appStore} alt="app store"/>
            </>
        }
    }

    return <div className='flex'>
        <div className='main'>
            <div className="logo">
                <img src={logo} alt="Шиномонтаж"/>
                <a href="tel:+7 995 236 77 57" className='main__phone'>+7 995 236 77 57</a>
            </div>
            {navigator.userAgent.match(/Windows/i) || navigator.userAgent.match(/MacOS/i) ?
                <div className="download qr">
                    <img src={qr}  alt="qr"/>
                    <p>Отсканируйте QR-код и воспользуйтесь нашим мобильным приложением </p>
                </div>
                :
                <div className="download">
                    <p>Начните пользоваться нашим мобильным приложением</p>
                    <a href="https://sabyget.page.link/?link=https://sabyget.ru/shop/know_name?company_id=9eaac781-48cc-4815-9323-30e84fc947c3&certificate=None&code_id=None&news_id=None&referee=None&apn=ru.tensor.showcase&isi=1559005656&ibi=ru.sabyget.sabyget-app&efr=1&st=Know+Name&si=https://sabyget.ru/previewer/disksharedrest/1f686b98-2a6e-4684-b069-bfab99f57a06">
                        {getPlatform()}
                    </a>
                </div>
            }
            <div className="btn-group">
                <p className='mobile'>Постройте свой маршрут удобным способом!</p>
                <iframe
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3A2d60dec433222a9d4eed4760e1c179eadae75ca72c0e8a72dde9eef5468ad664&amp;source=constructor"
                    width="698" height="294" frameBorder="0"></iframe>
                <a href='https://yandex.ru/navi/?whatshere[point]=30.013549075647443,59.83271954973038&whatshere[zoom]=18' className="btn">
                    <img src={yn} alt='навигатор'/>
                    Маршрут в Яндекс
                </a>
                <a href='https://go.2gis.com/t6o8s' className="btn">
                    <img src={twoGis} alt='навигатор'/>
                    Маршрут в 2GIS
                </a>
                <a href='https://goo.gl/maps/sSsfbrJ8SozXdwSV8' className="btn">
                    <img src={gm} alt='навигатор'/>
                    Маршрут в Google
                </a>
            </div>

        </div>
        <footer>
            <div className="logo">
                <img src={wLogo} alt="Шиномонтаж"/>
            </div>
            <div className="footer__link">
                <a href="tel:+7 995 236 77 57" className='footer__link_item phone'>+7 995 236 77 57</a>
                <p className='footer__link_item clock'>пн-вс 10:00 - 22:00</p>
                <a href="https://goo.gl/maps/sSsfbrJ8SozXdwSV8" className='footer__link_item geo desktop'>Стрельнинское шоссе, 4, деревня Верхняя Колония</a>
            </div>
            <div className="footer__link mobile">
                <a href="https://goo.gl/maps/sSsfbrJ8SozXdwSV8" className='footer__link_item geo'>Стрельнинское шоссе, 4, <br/> деревня Верхняя Колония</a>
            </div>
        </footer>
    </div>
};

export default Main;